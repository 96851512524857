<template>
  <div class="image-container center">
    <figure>
      <img :style="'width:' + (width || '90%')" :src="src" alt="Elephant at sunset" />
      <footer v-if="copyright" class="center image-footer">
        <small
          ><i>{{ copyright }}</i></small
        >
      </footer>
      <figcaption v-if="caption" class="center caption-height">
        <i>{{ caption }}</i>
      </figcaption>
    </figure>
  </div>
</template>

<script lang="ts" setup>
import type { ImageComponentProps } from '@tuxcy/types'

defineProps<ImageComponentProps>()
</script>

<style scoped>
.image-container {
  padding: var(--padding-image-container);
}
.image-footer {
  padding: 0;
}
.caption-height {
  line-height: 1em;
}
</style>
