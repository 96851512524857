html, body {
    height: 100%;
}


.brand {
    height: 50px;
    color: green;
    /*padding: 5px 10px;*/
}

.article-container h2 {
    /*border-top: 1px solid;*/
    padding-top: 1.8rem;
    line-height: 1em;
}

.impression-content {
    text-align: center;
    padding: 10px;
    line-height: 2em;
    font-style: italic;
}
