@media (max-width: 1024px) {
    .main-container {
        padding-left: 0px;
        display: block;
    }
    .main-content {
        padding: 1rem 0px;
    }
    .lateral-navbar {
        display: none !important;
    }
    .article-container {
        width: 100% !important;
    }
}

@media (min-width: 1024px) {
    .header-navbar {
        display: none !important;
    }
}
