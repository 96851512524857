.overflow-x-hidden {
    overflow-x: hidden;
}

.full-height {
    height: 100%;
}

.full-width {
    width: 100%;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.chevron::before {
    border-style: solid;
    border-width: 0.25em 0.25em 0 0;
    content: '';
    display: inline-block;
    height: 0.45em;
    left: 0.15em;
    position: relative;
    top: 0.15em;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 0.45em;
}

.chevron.right:before {
    left: 0;
    transform: rotate(45deg);
}

.chevron.bottom:before {
    top: 0;
    transform: rotate(135deg);
}

.chevron.left:before {
    left: 0.25em;
    transform: rotate(-135deg);
}
