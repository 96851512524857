:root {
    /* mvp.css default theme */
    --active-brightness: 0.85;
    --border-radius: 5px;
    --box-shadow: 2px 2px 10px;
    --color-accent: #118bee15;
    --color-bg: #fff;
    --color-bg-secondary: #e9e9e9;
    --color-link: #118bee;
    --color-secondary: #920de9;
    --color-secondary-accent: #920de90b;
    --color-shadow: #f4f4f4;
    --color-table: #118bee;
    --color-text: #000;
    --color-text-secondary: #999;
    --font-family: "Lucida Console", "Courier New", monospace;
    --hover-brightness: 1.2;
    --justify-important: center;
    --justify-normal: left;
    --line-height: 1.5;
    --width-card: 285px;
    --width-card-medium: 460px;
    --width-card-wide: 800px;
    --width-content: 1080px;
    /* custom props */
    --width-navbar: 200px;
    --padding-image-container: 2rem;
}
