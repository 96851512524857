.container {
    display: flex;
}

.item {
    padding: 2em;
    min-height: 500px;
}

.item-single {
    width: 100%;
}

.item {
    flex-grow: 1;
}

.main-container {
    position: relative;
    padding-left: calc(var(--width-navbar) + 20px);
    display: flex;
}
